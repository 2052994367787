<template>
    <div>
        <v-row justify="center" class="printpage">
            <v-col cols="0" md="12" class="hidden-print"></v-col>
            <v-col cols="12" md="4" class="text-center">
                <img src="@/assets/logo.png" style="width: 300px;" class="mt-12" />
            </v-col>
            <v-col cols="10">
                <p>
                    <b>Inzake het volgende object: </b> {{ taxatie.merk }} {{ taxatie.model }}
                    {{ taxatie.type }}
                </p>
                <p>
                    <b>Doel van deze waardebepaling:</b><br />
                    Waardebepaling t.b.v. import/BPM inclusief BPM berekening en BPM aangifte
                </p>
                <p>
                    <b>In opdracht van:</b><br />
                    {{ taxatie.klant.naam }} <br />
                    {{ taxatie.klant.straat }} {{ taxatie.klant.nummer }} <br />
                    {{ taxatie.klant.postcode }} {{ taxatie.klant.plaats }}
                </p>
                <p><b>Dossiernummer: </b> {{ taxatie.dossiernummer }}</p>
                <p v-if="false"><b>Datum: </b> {{ taxatie.rapportnummer }}</p>
                <p>
                    <b>Schade-export/Taxateur</b><br />
                    De heer Jamal El Karraz, Register Taxateur Hedendaagse Voertuigen. <br />
                    Daltonstraat 40, 1223RW, Hilversum<br />
                    Tel. +31 6 19739529
                </p>
                <p>
                    In opdracht van u hebben wij het hierna omschreven voertuig dat meer dan normale gebruiksschade
                    heeft en/of niet voorkomt in de voor de handel bekende algemene koerslijsten op
                    {{
                        taxatie.datum_taxatie
                            ? taxatie.datum_taxatie
                            : `${this.date.getDate()}-${this.date.getMonth() + 1}-${this.date.getFullYear()}`
                    }}
                    tijdstip aanvang
                    {{ taxatie.tijd_aanvang }}
                    uur, beëindiging {{ taxatie.tijd_vertrek }} uur geïnspecteerd diendend als waardebepaling.
                </p>
            </v-col>
        </v-row>
        <v-row justify="center" class="pagebreak printpage">
            <v-col cols="10">
                <p><span class="title">Opdrachtgever:</span></p>
                <p>
                    {{ taxatie.klant.naam }} <br />
                    {{ taxatie.klant.straat }} {{ taxatie.klant.nummer }} <br />
                    {{ taxatie.klant.postcode }} {{ taxatie.klant.plaats }}
                </p>
            </v-col>
            <v-col cols="10">
                <p><span class="title">Reden van taxatie:</span></p>
                <p>
                    Betreft een voertuig met meer dan normale gebruikersschade, niet zijnde een schadevoertuig als
                    bedoeld in artikel 1, eerste lid, onderdeel van de wegenverkeerswet 1994.
                </p>
            </v-col>
            <v-col cols="10">
                <p>
                    <span class="title">Identificatie / algemene voertuigegevens</span>
                </p>
                <p>
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td>
                                    Chassisnummer
                                </td>
                                <td>
                                    {{ taxatie.chassisnummer }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Merk
                                </td>
                                <td>
                                    {{ taxatie.merk }}
                                </td>
                            </tr>
                            <tr>
                                <td>Model/Type</td>
                                <td>{{ taxatie.model }} {{ taxatie.type }}</td>
                            </tr>
                            <tr>
                                <td>Datum eerste toelating</td>
                                <td>{{ taxatie.datum_eerste_toelating }}</td>
                            </tr>
                            <tr>
                                <td>KM-stand</td>
                                <td>{{ taxatie.km_stand }}</td>
                            </tr>
                            <tr>
                                <td>Kleur</td>
                                <td>{{ taxatie.kleur }}</td>
                            </tr>
                            <tr>
                                <td>Transmissie</td>
                                <td>{{ taxatie.transmissie }}</td>
                            </tr>
                            <tr>
                                <td>Carroserie</td>
                                <td>{{ decodeURIComponent(taxatie.carroserie) }}</td>
                            </tr>
                            <tr>
                                <td>Motorinhoud</td>
                                <td>{{ taxatie.motor_inhoud }}</td>
                            </tr>
                            <tr>
                                <td>Vermogen</td>
                                <td>{{ taxatie.vermogen }}</td>
                            </tr>
                            <tr>
                                <td>Brandstof</td>
                                <td>{{ taxatie.brandstof }}</td>
                            </tr>
                            <tr>
                                <td>Uitstoot</td>
                                <td>{{ taxatie.uitstoot }} {{ taxatie.uitstoot.includes('g/km') ? '' : 'g/km' }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </p>
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row justify="center" class="pagebreak printpage">
            <v-col cols="10">
                <p>
                    <span class="title">Staat voertuig</span>
                </p>
                <p>
                    <v-simple-table>
                        <tbody>
                            <tr class="big-table-row">
                                <td>
                                    Versnellingsbak
                                </td>
                                <td>
                                    {{ taxatie.staat.versnellingsbak }}
                                </td>
                            </tr>
                            <tr class="big-table-row">
                                <td>Wielophanging</td>
                                <td>{{ taxatie.staat.wielophanging }}</td>
                            </tr>
                            <tr class="big-table-row">
                                <td>Algehele staat</td>
                                <td>{{ taxatie.staat.algehele_staat }}</td>
                            </tr>
                            <tr class="big-table-row">
                                <td>Carrosserie</td>
                                <td>{{ taxatie.staat.carrosserie }}</td>
                            </tr>
                            <tr class="big-table-row">
                                <td>Remmen</td>
                                <td>{{ taxatie.staat.remmen }}</td>
                            </tr>
                            <tr class="big-table-row">
                                <td>Motor</td>
                                <td>{{ taxatie.staat.motor }}</td>
                            </tr>
                            <tr class="big-table-row">
                                <td>Interieur</td>
                                <td>{{ taxatie.staat.interieur }}</td>
                            </tr>
                            <tr class="big-table-row">
                                <td>Stuurinrichting</td>
                                <td>{{ taxatie.staat.stuurinrichting }}</td>
                            </tr>
                            <tr class="big-table-row">
                                <td>Velgen en banden</td>
                                <td>{{ taxatie.staat.velgen_banden }}</td>
                            </tr>
                            <tr class="big-table-row">
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </p>
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row justify="center" class="pagebreak printpage">
            <v-col cols="10">
                <p>
                    <span class="title">
                        Waardebepaling
                    </span>
                </p>
                <p>
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td>Oorspronkelijke NL Cataloguswaarde<br />(Inclusief accessoires conform bijlage)</td>
                                <td>€ {{ beautifyNumber(taxatie.cataloguswaarde) }},-</td>
                            </tr>
                            <tr>
                                <td>Handelswaarde conform bijlage</td>
                                <td>€ {{ beautifyNumber(taxatie.handelswaarde) }},-</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>-/-</td>
                            </tr>
                            <tr>
                                <td>Schadebedrag</td>
                                <td>€ {{ beautifyNumber(taxatie.schade_bedrag) }},-</td>
                            </tr>
                            <tr>
                                <td>Waarde op basis van inkoop handel</td>
                                <td>
                                    €
                                    {{
                                        beautifyNumber(Number(taxatie.handelswaarde) - Number(taxatie.schade_bedrag))
                                    }},-
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </p>
                <p>
                    De genoemde handelswaardes zijn gebaseerd aan de hand van koerslijstonderzoek, marktonderzoek en
                    hierin is rekening gehouden met de volgende emolumenten: schadeverleden reeds bekend -
                    schadeverleden na herstel - garantie en coulance mogelijkheden - afwijkende specificaties.
                </p>
                <p>
                    Het percentage van de gecalculeerde schade dat in mindering gebracht is op de handelsinkoopwaarde in
                    onbeschadigde staat is gesteld op 100% van de gecalculeerde schade omdat de in de fotoreportage
                    vastgelegde gebruikssporen niet in de bijbehorende schadecalculatie zijn opgenomen en omdat de in de
                    fotoreportage geconstateerde schade in verschillende gradaties van herstel is opgenomen in de
                    bijbehorende schadecalculatie.
                </p>
                <p>
                    <b>Opmerkingen:</b><br />
                    Ik J. Karraz verklaar uitdrukkelijk en zonder voorbehoud dat deze taxatie tot stand is gekomen
                    zonder enige partijdigheid of afhankelijkheid als bedoeld in Wijziging van enige fiscale
                    uitvoeringsregelingen als gepubliceerd in de Staatscourant nr. 22974 d.d. 30-12-2011. De rapportage
                    is voor dit doel van rechtswege geldig tot 1 maand na afgifte datum Door ons is geen onderzoek
                    ingesteld naar de oorzaak van de eventuele schade c.q. de reden van afwezigheid van gedemonteerde
                    onderdelen.<br />
                    De waarde is gebaseerd op de waarde van een gelijksoortige, reeds op het nationale grondgebied
                    geregistreerde auto. Aldus gedaan naar beste weten en kunnen, om te dienen waar zulks nodig is.
                    <br /><br />
                    Te Hilversum,
                    {{
                        taxatie.rapportnummer
                            ? taxatie.rapportnummer
                            : `${this.date.getDate()}-${this.date.getMonth() + 1}-${this.date.getFullYear()}`
                    }}
                    <br /><br />
                    Hoogachtend,<br />
                    <img src="@/assets/handtekening.png" class="handtekening" /><br />
                    J.Karraz<br />
                    Register Taxateur hedendaagse voertuigen (RTIV15WW1811)
                </p>
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row justify="center" class="pagebreak printpage">
            <v-col cols="10">
                <p class="title">
                    Acceptabele gebruikssporen in relatie tot voertuigleeftijd.
                </p>
                <p>
                    <b>Definitie</b><br />
                    Met "gebruikssporen" wordt in dit document bedoeld "normale gebruiksschade", artikel 2, sub c, Wet
                    BPM: "Normale gebruiksschade: slijtage en kleine beschadigingen die ontstaan door gebruik van een
                    voertuig en die passen bij de leeftijd en kilometrage van het voertuig."
                </p>
                <p>
                    <b>Autotelex koerslijstwaarde</b><br />
                    De koerslijst waarde van Autotelex is gebasseerd op normale gebruikssporen, passend bij de
                    kilometerstand en leeftijd van de auto.
                </p>
                <p>
                    <b>Gebruikssporen</b><br />
                    Naarmate de leeftijd of de kilometerstand hoger zijn, zullen ook de toelaatbare gebruikssporen
                    toenemen.<br />
                    De meeste overzichtelijke manier om dit te kwantificeren is het introduceren van een matrix met
                    bedragen noodzakelijk om een auto weer in perfecte staat* te brengen. Dit bedrag vertegenwoordigt
                    daarmee het verschil tussen een auto in gemiddelde staat (Autotelex norm) en een auto in perfecte
                    staat.
                </p>
                <p>
                    <img :src="server + '/fotos/tabellen/reparatie_tabel.png'" class="reparatie-tabel" />
                </p>
                <p>
                    <b>Toelichting tabel</b> De tabel vertegenwoordigt het bedrag noodzakelijk om een auto met normale
                    gebruikssporen weer in perfecte staat te brengen voor de meest gangbare segmenten.
                </p>
                <p>
                    Onder normale gebruikssporen wordt verstaan: <br />
                    <b>Exterieur:</b><br />
                    Steenslag, lichtekrasvorming (denk aan rondom portiergrepen), lichte wielbeschadiging
                    (stoeprandschade).
                </p>
                <p>
                    <b>Interieur:</b><br />
                    Slijtagesporen van veelvuldig gebruikte interieurdelen. Denk aan pedalen, versnellingsknop, stoelen.
                </p>
                <p>
                    De gradatie hierin neemt toe met de leeftijd en kilometerstand en komt tot uiting in het bedrag in
                    de tabel, noodzakelijk om e.e.a. weer te herstellen.
                </p>
                <p style="margin-top: 32px;">
                    <i>
                        Dit rapport kan niet dienen voor aankop of verkoopadvies. Ook kunnen er geen rechten aan worden
                        ontleend.
                    </i>
                </p>
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row justify="center" class="pagebreak printpage">
            <v-col cols="10">
                <p class="title">
                    Referentievoertuigen:
                </p>
                <p>
                    <img class="referentie-image" :src="getImageUrl(taxatie.gaspedaal)" />
                </p>
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row justify="center" class="pagebreak printpage">
            <v-col cols="10">
                <p class="title">
                    Waardebepaling:
                </p>
                <p>
                    <b>Toelichting markt/koerslijstonderzoek:</b><br />
                    De bepaling van deze koerslijst is gebasseerd op de volgende factoren: courantheid, leeftijd,
                    km-stand, fabrieksopties, accessoires en historie. Schadevrij en technisch in goede conditie is
                    voorwaarde. <br />
                    Normale gebruikssporen afhankelijk van leeftijd en km. stand zijn ingecalculeerd. <br />
                    De waarde van deze elektronische koerslijst voor niet-schadevoertuigen reflecteert daarmee de
                    daadwerkelijkse handelsinkoopwaarde schadevrij in het economisch verkeer.
                </p>
                <p class="text-center">
                    <img :src="getImageUrl(taxatie.koerslijst)" class="koerslijst-max-height" />
                </p>
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row v-if="taxatie.rental === 'ja'" justify="center" class="pagebreak printpage">
            <v-col cols="12" class="text-right show-print">
                <img src="@/assets/logo.png" style="width: 120px; margin-right: 24px;" />
            </v-col>
            <v-col cols="10">
                <p class="subtitle"><b>Waardebepaling:</b> {{ taxatie.merk }} {{ taxatie.model }} {{ taxatie.type }}</p>
                <p>
                    <img :src="getImageUrl(taxatie.fotos[0])" style="max-height: 75vh; max-width: 90vw; width: auto;" />
                </p>
                <p class="subtitle"><b>Toelichting:</b> Dit is een huurauto.</p>
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row justify="center" class="pagebreak printpage">
            <v-col cols="10">
                <p class="title">
                    BPM overzicht:
                </p>
                <p>
                    <img v-if="taxatie.bpm" :src="getImageUrl(taxatie.bpm)" />
                    <v-simple-table v-else>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="headline text-left">
                                        BPM op basis van taxatierapport
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">
                                        Nieuwprijs incl. opties / accessoires
                                    </td>
                                    <td>&euro; {{ beautifyNumber(taxatie.cataloguswaarde) }},-</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Totale handelswaarde
                                    </td>
                                    <td>
                                        &euro;
                                        {{
                                            beautifyNumber(taxatie.handelswaarde_taxatierapport) ||
                                                beautifyNumber(
                                                    parseNumber(taxatie.handelswaarde) -
                                                        parseNumber(taxatie.schade_bedrag)
                                                )
                                        }},-
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Werkelijke afschrijving ({{ getBPMPercentage(taxatie.bpmData.aftrek) }})
                                    </td>
                                    <td>
                                        &euro;
                                        {{
                                            beautifyNumber(
                                                parseNumber(taxatie.cataloguswaarde) -
                                                    (parseNumber(taxatie.handelswaarde) -
                                                        parseNumber(taxatie.schade_bedrag))
                                            )
                                        }},-
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Bruto-BPM
                                    </td>
                                    <td>&euro; {{ beautifyNumber(taxatie.bpmData.historisch) }},-</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Afschrijving BPM ({{ getBPMPercentage(taxatie.bpmData.aftrek) }})
                                    </td>
                                    <td>
                                        &euro;
                                        {{ beautifyNumber(taxatie.bpmData.historisch - taxatie.bpmData.rest) }},-
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Rest-BPM op basis van taxatierapport
                                    </td>
                                    <td>&euro; {{ beautifyNumber(taxatie.bpmData.rest) }},-</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </p>
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row justify="center" class="pagebreak printpage">
            <v-col cols="12" class="text-right show-print">
                <img src="@/assets/logo.png" style="width: 120px; margin-right: 24px;" />
            </v-col>
            <v-col cols="10">
                <p class="title">
                    Foto bijlage:
                </p>
                <p>
                    <u>
                        Op onderstaande foto's wordt de schade aangetoond, hierbij wordt de schade omcirkelt en/of
                        aangewezen op de best mogelijke manier.<br />
                        Mogelijke afwijking: mochten wij op foto's aangeven dat een onderdeel hersteld moet worden maar
                        blijkt uit de schadecalculate dat vervangen voordeliger is dan wordt hiervoor gekozen.
                    </u>
                </p>
            </v-col>
            <v-col cols="4" class="text-center" v-for="foto in taxatieFotosEerstePagina" :key="foto">
                <img class="img-max-height" :src="getImageUrl(foto)" />
            </v-col>

            <v-col cols="12" class="text-right show-print paraaf-div">
                <b>Paraaf: </b>
                <img src="@/assets/handtekening.png" style="width: 80px;" /><br />
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row
            justify="center"
            class="pagebreak printpage img-page-height"
            v-for="collectie in taxatieFotoCollecties"
            :key="collectie.id"
        >
            <v-col cols="12" class="text-right show-print">
                <img src="@/assets/logo.png" style="width: 120px;" />
            </v-col>
            <v-col cols="4" class="text-center" v-for="foto in collectie.fotos" :key="foto">
                <img v-if="foto" class="img-max-height" :src="getImageUrl(foto)" />
            </v-col>
            <v-col cols="12" class="align-self-end show-print">
                <v-row justify="end">
                    <v-col cols="6" class="text-right">
                        <p class="paraaf-div">
                            <b>Paraaf: </b>
                            <img src="@/assets/handtekening.png" style="width: 80px;" /><br />
                        </p>
                    </v-col>
                </v-row>
            </v-col>
            <taxatie-footer />
        </v-row>
        <v-row justify="center" class="pagebreak-img" v-for="foto in taxatie.vwe" :key="foto">
            <v-col cols="12" class="text-center">
                <img :src="getImageUrl(foto, 'vwe')" class="pdf-max-height" />
                <taxatie-footer />
            </v-col>
        </v-row>
        <v-row class="pagebreak-img" v-for="foto in taxatie.aangifteformulier" :key="foto">
            <v-col cols="12" class="text-center">
                <img :src="getImageUrl(foto, 'aangifteformulier')" class="pdf-max-height" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from '../../axios';

import TaxatieFooter from '../Shared/TaxatieFooter.vue';

const IMAGES_PER_PAGE = 12;
const START_INDEX = 6;

export default {
    data() {
        return {
            date: new Date(),
            server: axios.defaults.baseURL
        };
    },
    props: {
        taxatie: {
            type: Object,
            required: true
        }
    },
    methods: {
        beautifyNumber(number) {
            if (number) {
                return number
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    .replace(/,-/g, '');
            } else if (number === 0) {
                return '0';
            }
            return '';
        },
        parseNumber(number) {
            if (number) {
                return String(number)
                    .replace(',-', '')
                    .replace('.', '');
            }
            return '';
        },
        getImageUrl(image, folder = '') {
            const dateArr = this.taxatie.datum_taxatie.split('-');
            const year = Number(dateArr[2]);
            const month = Number(dateArr[1]);

            if (year === 2022 && month >= 6) {
                if (image.includes('~')) {
                    return `${this.server}/fotos/${folder}${folder ? '/' : ''}${
                        image.split('~')[0]
                    }.png?${new Date().getTime()}`;
                }
            }
            return `${this.server}/fotos/${folder}${folder ? '/' : ''}${image}?${new Date().getTime()}`;
        },
        getBPMPercentage() {
            if (this.taxatie.bpmData.aftrek.includes('%')) {
                return this.taxatie.bpmData.aftrek;
            }
            const aftrekWaarde =
                this.parseNumber(this.taxatie.cataloguswaarde) -
                (this.parseNumber(this.taxatie.handelswaarde) - this.parseNumber(this.taxatie.schade_bedrag));
            return `${((aftrekWaarde / this.parseNumber(this.taxatie.cataloguswaarde)) * 100).toFixed(1)}%`;
        }
    },
    computed: {
        taxatieFotosEerstePagina() {
            if (this.taxatie.rental === 'ja') {
                return this.taxatie.fotos.slice(1, START_INDEX + 1);
            }
            return this.taxatie.fotos.slice(0, START_INDEX);
        },
        taxatieFotoCollecties() {
            const result = [];
            const collectiesLength = Math.ceil(
                (this.taxatie.fotos.length - START_INDEX - (this.taxatie.rental === 'ja' ? 1 : 0)) / IMAGES_PER_PAGE
            );
            for (let i = 0; i < collectiesLength; i++) {
                const startIndex = i * IMAGES_PER_PAGE + START_INDEX + (this.taxatie.rental === 'ja' ? 1 : 0);
                const fotos = this.taxatie.fotos.slice(startIndex, startIndex + IMAGES_PER_PAGE);
                result.push({
                    id: Math.random(),
                    fotos
                });
            }
            return result;
        }
    },
    components: {
        TaxatieFooter
    }
};
</script>

<style scoped>
img {
    width: 100%;
}
tr {
    border-top: 1px solid #ccc !important;
}

.handtekening {
    width: 400px;
}
.reparatie-tabel {
    width: 600px;
}

.pdf-max-height {
    height: 80%;
    max-height: 80vh;
    width: auto;
}
.koerslijst-max-height {
    max-height: 65vh;
}
.img-max-height {
    max-width: 280px;
    margin: auto;
}

.referentie-image {
    max-width: 70% !important;
}

@media print {
    .img-page-height {
        height: 290mm;
    }

    .referentie-image {
        max-width: 90vw;
        min-width: 100%;
        height: auto;
    }

    .height-spacer {
        height: 60px;
    }

    .pdf-max-height {
        height: 100vh;
        max-height: 1000px !important;
        width: auto;
    }
    .img-max-height {
        max-height: 12rem;
        margin: 0 0.25rem;
        width: auto;
        max-width: 220px;
    }
    .koerslijst-max-height {
        max-height: 800px !important;
        max-width: 90vw;
        width: auto;
    }

    @page {
        margin: 0cm;
        size: 210mm 297mm;
    }
    .pagebreak-img {
        display: block;
        margin: auto;
        page-break-before: always;
        /* page-break-after: always; */
        page-break-inside: auto;
    }
    .paraaf-div {
        position: absolute !important;
        bottom: 2rem !important;
        right: 4rem !important;
    }
    .big-table-row {
        height: 80px !important;
    }
}

@media only screen and (max-width: 600px) {
    .handtekening {
        width: 80vw;
    }
    .reparatie-tabel {
        width: 80vw;
    }
    .img-max-height {
        display: block;
        width: 30vw !important;
    }
    .pdf-max-height {
        max-height: 80vh;
        width: auto;
        margin: auto;
    }
    .img-page-height {
        height: auto;
    }
}
</style>

<template>
    <v-container>
        <div v-if="!isLoading && (Object.keys(taxatie).length !== 0 || taxaties.length > 0)">
            <taxatie-preview-block :isShare="sharePreview" :taxatie="taxatie" />
            <div v-if="multipleToggle">
                <taxatie-preview-document v-for="taxatie in taxaties" :key="taxatie.id" :taxatie="taxatie" />
            </div>
            <div v-else>
                <taxatie-preview-document v-if="Object.keys(taxatie).length > 0" :taxatie="taxatie" />
            </div>
            <taxatie-preview-block :isShare="sharePreview" />
        </div>
        <loading-component v-if="isLoading"></loading-component>
        <v-row
            v-if="!isLoading && Object.keys(taxatie).length === 0 && taxaties.length === 0"
            justify="center"
            align="center"
            style="height: 80vh;"
        >
            <v-col cols="6" class="text-center">
                <span class="display-2">
                    U heeft geen rechten om deze taxatie te bekijken
                </span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from '../../axios';

import TaxatiePreviewBlock from './TaxatiePreviewBlock.vue';
import TaxatiePreviewDocument from './TaxatiePreviewDocument.vue';

import LoadingComponent from '../Shared/LoadingComponent.vue';
import Store from '../../store';

export default {
    data() {
        return {
            date: new Date(),
            taxatie: {},
            taxaties: [],
            server: axios.defaults.baseURL,
            isLoading: true,
            sharePreview: false,
            multipleToggle: location.href.includes('multiple')
        };
    },
    async mounted() {
        await this.initPreview();
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            document.documentElement.style.setProperty('--pdf-height', '70vh');
            document.documentElement.style.setProperty('--koerslijst-height', '50vh');
        } else {
            document.documentElement.style.setProperty('--pdf-height', '100vh');
            document.documentElement.style.setProperty('--koerslijst-height', '70vh');
        }
    },
    watch: {
        async '$route.params.access_token'() {
            await this.initPreview();
        }
    },
    methods: {
        async initPreview() {
            if (this.$route.params.access_token) {
                this.sharePreview = true;
                this.$emit('isShare');
                await this.getSharedData();
            } else {
                await this.getData();
            }
        },
        async getData() {
            if (this.multipleToggle) {
                try {
                    const taxatiesString = Store.get('printTaxaties');

                    console.log(taxatiesString);

                    if (taxatiesString) {
                        const taxaties = JSON.parse(taxatiesString);

                        this.taxaties = await Promise.all(
                            taxaties.map(async id => (await axios.get(`/taxaties/${id}`)).data)
                        );
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    document.title = 'Meerdere raporten';
                    this.isLoading = false;
                }
                return;
            }
            try {
                this.taxatie = (await axios.get(`/taxaties/${this.$route.params.id}`)).data;
            } catch (error) {
                document.title = 'Geen rechten';
                console.error(error);
            } finally {
                document.title = this.parseTitle(this.taxatie);
                this.isLoading = false;
            }
        },
        async getSharedData() {
            try {
                this.taxatie = (
                    await axios.get(`/sharedtaxaties/${this.$route.params.id}/${this.$route.params.access_token}`)
                ).data;
            } catch (error) {
                if (error.response.data.code === 'WRONG_DATE') {
                    alert('Rapport verlopen');
                } else {
                    alert('Verkeerde access token.');
                }
                this.$router.push('/login');
            } finally {
                document.title = this.parseTitle(this.taxatie);
                this.isLoading = false;
            }
        },
        parseTitle(taxatie) {
            if (!taxatie || Object.keys(taxatie).length === 0) {
                return '';
            }
            return `RAPPORT-${taxatie?.merk.toUpperCase()}-${taxatie?.model} ${taxatie?.type
                .split(' ')[0]
                .toUpperCase()}-${taxatie.chassisnummer.trim().substr(-4)}`;
        }
    },
    components: {
        LoadingComponent,
        TaxatiePreviewBlock,
        TaxatiePreviewDocument
    },
    metaInfo() {
        return {
            title: this.parseTitle(this.taxatie),
            titleTemplate: '%s'
        };
    }
};
</script>

<style scoped>
.container {
    font-size: 10pt;
}
</style>

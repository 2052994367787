<template>
    <v-row justify="center" class="hidden-print">
        <v-col cols="12" v-if="admin">
            <transition name="slide-fade">
                <v-alert v-if="showDialog" type="info" prominent>
                    Wilt je deze taxatie op geprint zetten?
                    <v-btn @click="setPrinted" style="float: right;" small>Zet op geprint</v-btn>
                </v-alert>
            </transition>
        </v-col>
        <v-col cols="12" md="8" v-if="isShare">
            <v-text-field hide-details id="url" outlined v-model="url"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" v-if="isShare">
            <v-btn @click="copyUrl" class="mt-1" block color="secondary" large>
                Kopieer link
            </v-btn>
        </v-col>
        <v-col cols="6" :md="admin ? 3 : 6" v-if="!isShare">
            <v-btn @click="goBack" block color="secondary">
                Terug
            </v-btn>
        </v-col>
        <v-col cols="6" md="3" v-if="!isShare && admin">
            <v-btn @click="edit" block color="secondary">
                Aanpassen
            </v-btn>
        </v-col>
        <v-col :cols="isShare ? 12 : 6" :md="isShare ? 12 : admin ? 3 : 6">
            <v-btn @click="printPage" block color="primary" :x-large="isShare">
                Print
            </v-btn>
        </v-col>
        <v-col cols="6" md="3" v-if="!isShare && admin">
            <v-btn @click="sharePage" block dark color="purple">
                <span v-if="!isLoading">
                    Delen
                </span>
                <v-progress-circular v-else class="ma-auto" indeterminate color="primary"></v-progress-circular>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import axios from '../../axios';
import Store from '../../store';

export default {
    data() {
        return {
            admin: Store.get('admin'),
            url: window.location.href,
            isLoading: false,
            showDialog: false
        };
    },
    watch: {
        async '$route.params.access_token'() {
            this.url = window.location.href;
        }
    },
    props: ['isShare', 'taxatie'],
    methods: {
        printPage() {
            window.print();

            if (!this.taxatie.printed) {
                this.showDialog = true;
            }
        },
        goBack() {
            this.$router.push('/taxaties/lijst');
        },
        edit() {
            this.$router.push(`/taxaties/maak/${this.$route.params.id}`);
        },
        async sharePage() {
            try {
                this.isLoading = true;
                const { data } = await axios.put(`/taxaties/share/${this.$route.params.id}`);
                this.$router.push(`/taxaties/gedeeld/${data.url}`);
            } catch (error) {
                console.error(error);
                alert('Er ging iets fout bij het delen, probeer opnieuw.');
                this.isLoading = false;
            }
        },
        copyUrl() {
            const input = document.querySelector('#url');
            input.focus();
            input.select();
            document.execCommand('copy');
            console.log(input);
        },
        async setPrinted() {
            try {
                await axios.put(`/taxaties/printed/${this.taxatie._id}`, { value: true });
                this.showDialog = false;
            } catch (error) {
                console.error(error);
                alert('Er ging iets fout bij het delen, probeer opnieuw.');
            }
        }
    }
};
</script>
